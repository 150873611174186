<template>
  <div>
    <item-autocomplete
      v-model="innerValue.author"
      :load="loadDoctors"
      item-text="fullName"
      item-value="id"
      hide-details
      label="Автор"
      placeholder="Выберите автора"
      name="doctor-autocomplete"
      hide-no-data
    />
    <v-checkbox
      label="Показывать этот пост на странице доктора"
      v-model="innerValue.showOnAuthorPage"
    />

    <br>

    <item-autocomplete
      v-model="innerValue.organization"
        :load="loadClinics"
        item-text="name"
        item-value="id"
        label="Клиника"
        hide-no-data
        placeholder="Выберите клинику"
        name="clinic-autocomplete"
        hide-details
    />
    <v-checkbox
      label="Показывать этот пост на странице клиники"
      v-model="innerValue.showOnClinicPage"
    />

    <br>

    Симптомы
    <multiselect
      v-model="innerValue.symptoms"
      id="symptoms"
      @search-change="loadSymptoms"
      :loading="symptoms.loading"
      :internal-search="false"
      :options="symptoms.items"
      :allow-empty="true"
      :show-labels="true"
      :searchable="true"
      :taggable="true"
      :multiple="true"
      placeholder="Симптомы"
      track-by="id"
      label="name"
    />

    Заболевания
    <multiselect
      v-model="innerValue.diseases"
      id="diseases"
      @search-change="loadDiseases"
      :loading="diseases.loading"
      :internal-search="false"
      :options="diseases.items"
      :allow-empty="true"
      :show-labels="true"
      :searchable="true"
      :taggable="true"
      :multiple="true"
      placeholder="Заболевания"
      track-by="id"
      label="name"
    />

    Процедуры
    <multiselect
      v-model="innerValue.procedures"
      id="diseases"
      @search-change="loadProcedures"
      :loading="procedures.loading"
      :internal-search="false"
      :options="procedures.items"
      :allow-empty="true"
      :show-labels="true"
      :searchable="true"
      :taggable="true"
      :multiple="true"
      placeholder="Процедуры"
      track-by="id"
      label="name"
    />

  </div>
</template>

<script>
import doctorsService from '../../services/doctors'
import clinicsService from '../../services/clinics'
import ItemAutocomplete from './ItemAutocomplete.vue'
import isEqual from 'lodash/isEqual'
import symptomsService from '../../services/symptoms'
import diseasesService from '../../services/diseases'
import proceduresService from '../../services/procedures'

export default {
  components: {
    ItemAutocomplete
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      symptoms: {
        loading: false,
        items: []
      },
      diseases: {
        loading: false,
        items: []
      },
      procedures: {
        loading: false,
        items: []
      },
      innerValue: this.value ? { ...this.value } : {}
    }
  },
  watch: {
    value (value) {
      if (!isEqual(value, this.innerValue)) {
        this.innerValue = value
      }
    },
    innerValue: {
      deep: true,
      handler (innerValue) {
        this.$emit('input', innerValue)
      }
    }
  },
  methods: {
    async loadDoctors (search) {
      const { items } = await doctorsService.getAll({ search })
      return items
    },
    async loadClinics (search) {
      const { items } = await clinicsService.getAll({ search })
      return items
    },
    async loadSymptoms (search) {
      this.symptoms.loading = true
      const { items } = await symptomsService.getAll({ search })
      this.symptoms.items = items
      this.symptoms.loading = false
    },
    async loadDiseases (search) {
      this.diseases.loading = true
      const { items } = await diseasesService.getAll({ search })
      this.diseases.items = items
      this.diseases.loading = false
    },
    async loadProcedures (search) {
      this.procedures.loading = true
      const { items } = await proceduresService.getAll({ search })
      this.procedures.items = items
      this.procedures.loading = false
    }
  }
}
</script>
